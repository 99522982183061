<template>
	<div id="practical-information">
		<div class="go-back">
			<router-link :to="'/plan'">
				<i class="fas fa-arrow-left"></i>&nbsp;&nbsp;
				Go back
			</router-link>
		</div>
		<div class="content">
			<p class="title" data-aos="zoom-out">Practical Information</p>
			<div class="box-list" data-aos="zoom-out">
				<div class="img text-center">
					<img :src="img01" class="img-pra" alt="img01">
				</div>
				<div class="detail">
					<p>Located in East Asia, Mongolia is a landlocked country bordering Russia and China.</p>
					<router-link :to="{ name: 'map', query: { ac: 1 }}" class="btn btn-practical">World Map</router-link>
				</div>
			</div>
			<div class="box-list" data-aos="zoom-out">
				<div class="img text-center">
					<img :src="img02" class="img-pra" alt="img02">
				</div>
				<div class="detail">
					<p>The climate of Mongolia can be generally defined as extreme continental. It has long, cold winters and short summers. In the North, it is cool and subarctic. Central zones are cold semi-arid and the South is desert. Average temperatures are mostly below zero from November to March and close to it in April and October throughout most of Mongolia. However, in the summer temperatures are mild to very hot reaching 40°C in some places such as the Gobi Desert.</p>
					<a href="https://weather.com/weather/today/l/MGXX0003:1:MG" target="_blank" rel="noopener noreferrer" class="btn btn-practical">Weather Forecast</a>
				</div>
			</div>
			<div class="box-list" data-aos="zoom-out">
				<div class="img text-center">
					<img :src="img03" class="img-pra" alt="img03">
				</div>
				<div class="detail">
					<p>The official currency of Mongolia is tögrögor tugrik(MNT). The lowest denomination is a 10 tögrögnote and the highest is a 20,000 tögrögnote. 1 USD is about 2,600 MNT</p>
					<a href="https://www.xe.com/currencyconverter/convert/?Amount=1&From=USD&To=MNT" target="_blank" rel="noopener noreferrer" class="btn btn-practical">Calculate</a>
				</div>
			</div>
			<div class="box-list" data-aos="zoom-out">
				<div class="img text-center">
					<img :src="img04" class="img-pra" alt="img04">
				</div>
				<div class="detail">
					<p>Overall, Mongolia is a safe country to visit. Violence is rare but petty crimes such as theft and scams may happen from time to time. Like any other country, when traveling, always be vigilant of your belongings and surroundings. Try not to travel alone at night and avoid dodgy areas, especially in the city. In the countryside, beware of stray dogs and rough or even hazardous roads.</p>
				</div>
			</div>
			<div class="box-list" data-aos="zoom-out">
				<div class="img text-center">
					<img :src="img05" class="img-pra" alt="img05">
				</div>
				<div class="detail">
					<p>The official language of Mongolia is Khalkha Mongolian, which is spoken by 90% of the population.There are certain variation of dialects in different parts of the country. Most used foreign languages are Russian, English and some Chinese as well.</p>
				</div>
			</div>

			<table class="table table-bordered table-pra" data-aos="zoom-out">
				<thead>
					<tr class="theader text-center">
						<th style="width: 300px;">English</th>
						<th>Mongolia</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Welcome	</td>
						<td>Тавтай морилогтун (tavtai morilogtun)</td>
					</tr>
					<tr>
						<td>Hello (General greeting)</td>
						<td>Сайн уу? (Sain uu?)<br/>Сайн байна уу? (Sain baina uu)</td>
					</tr>
					<tr>
						<td>Hello (on phone)</td>
						<td>Байна уу (Baina uu)</td>
					</tr>
					<tr>
						<td>How are you?</td>
						<td>
							Сонин сайхан юу байна вэ?<br/>
							(Sonin saikhan yu baina ve?)<br/>
							Юу байна вэ?<br/>
							(Yu baina ve?)
						</td>
					</tr>
					<tr>
						<td>Where are you from?</td>
						<td>
							Та хаанаас ирсэн бэ<br/>
							(Ta haanaas irsen be?)
						</td>
					</tr>
					<tr>
						<td>I’m from …</td>
						<td>
							Би …ээс ирсэн<br/>
							(Bi …ees irsen)
							</td>
					</tr>
					<tr>
						<td>Pleased to meet you</td>
						<td>
							Тантай уулзсандаа баяртай байна<br/>
							(Tantai uulzsandaa bayartai baina)
						</td>
					</tr>
				</tbody>
			</table>

			<table class="table table-bordered table-pra" data-aos="zoom-out">
				<tbody>
					<tr>
						<td style="width: 300px;">
							Good morning <br/>
							(Morning greeting)
						</td>
						<td>
							Өглөөний мэнд (Öglöönii mend)
						</td>
					</tr>
					<tr>
						<td>
							Good afternoon <br/>
							(Afternoon greeting)
						</td>
						<td>
							Өдрийн мэнд (Ödriin mend)
						</td>
					</tr>
					<tr>
						<td>
							Good evening <br/>
							(Evening greeting)
						</td>
						<td>
							Оройн мэнд (Oroin mend)
						</td>
					</tr>
					<tr>
						<td>
							Good night
						</td>
						<td>
							Сайхан амраарай (Saikhan amraarai)<br/>
							Сайхан нойрсоорой (Saikhan noirsooroi)
						</td>
					</tr>
					<tr>
						<td>
							Goodbye <br/>
							(Parting phrases)
						</td>
						<td>
							Баяртай (Bayartai)
						</td>
					</tr>
					<tr>
						<td>
							See you later
						</td>
						<td>
							Дараа уулзая (Daraa uulzii!) 
						</td>
					</tr>
					<tr>
						<td>
							See you tomorrow
						</td>
						<td>
							Маргааш уулзая (Margaash uulzii)
						</td>
					</tr>
					<tr>
						<td>
							Good luck!
						</td>
						<td>
							Амжилт хүсье (Amjilt husey)
						</td>
					</tr>
					<tr>
						<td>
							Have a nice day
						</td>
						<td>
							Өдрийг сайхан өнгөрүүлээрэй <br/>
							(Odriig saihan ongoruuleerei)
						</td>
					</tr>
					<tr>
						<td>
							Bon voyage / <br/>
							Have a good journey
						</td>
						<td>
							Сайн яваарай! (Sain yavaarai!)<br/>
							Сайн сууж байгаарай (Sain suuj baigaarai!) - reply
						</td>
					</tr>
				</tbody>
			</table>

			<table class="table table-bordered table-pra" data-aos="zoom-out">
				<tbody>
					<tr>
						<td style="width: 300px;">I understand</td>
						<td>Би ойлгой байна (Bi oilgoj baina)</td>
					</tr>
					<tr>
						<td>I don’t understand</td>
						<td>Би ойлгохгүй байна (Bi oilgokhgüi baina)</td>
					</tr>
					<tr>
						<td>Please say that again</td>
						<td>Дахин хэлж өгнө үү? (Dahin helj ügnü üü?)</td>
					</tr>
					<tr>
						<td>Please write it down</td>
						<td>Үүнийг доор бичнэ үү (Uuniig door bichne uu)</td>
					</tr>
				</tbody>
			</table>

			<table class="table table-bordered table-pra" data-aos="zoom-out">
				<tbody>
					<tr>
						<td style="width: 300px;">Excuse me</td>
						<td>Маргааш уулзая (Margaash uulzii)</td>
					</tr>
					<tr>
						<td>How much is this?</td>
						<td>Маргааш уулзая (Margaash uulzii)</td>
					</tr>
					<tr>
						<td>Sorry</td>
						<td>Дахин хэлж өгнө үү? (Dahin helj ügnü üü?)</td>
					</tr>
					<tr>
						<td>Thank you</td>
						<td>Үүнийг доор бичнэ үү (Uuniig door bichne uu)</td>
					</tr>
					<tr>
						<td>Where's the toilet ?</td>
						<td>
							Бие засах газар хаана байдаг вэ<br/>
							(Biye zasakh gazar khaana baidag ve?)
						</td>
					</tr>
				</tbody>
			</table>

			<table class="table table-bordered table-pra" data-aos="zoom-out">
				<tbody>
					<tr>
						<td style="width: 300px;">Fire!</td>
						<td>Гал (Gal!)</td>
					</tr>
					<tr>
						<td>Stop!</td>
						<td>Зогс (Zogs!)</td>
					</tr>
					<tr>
						<td>Call the police!</td>
						<td>Цагдаа дуудаарай! (Tsagdaa duudaarai!)</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import('aos').then(AOS => AOS.init());
import img01 from '../../assets/img/img-pra-01.png'
import img02 from '../../assets/img/img-pra-02.png'
import img03 from '../../assets/img/img-pra-03.png'
import img04 from '../../assets/img/img-pra-04.png'
import img05 from '../../assets/img/img-pra-05.png'

export default {
	name: "PracticalInformation",
	data() {
		return {
			img01 : img01,
			img02 : img02,
			img03 : img03,
			img04 : img04,
			img05 : img05,
		}
	}  
};
</script>

<style lang="scss" scoped>
	#practical-information {
		width: 100%;
		height: 100%;
		padding: 0 50px 0;

		.go-back {
			width: 100%;
			height: 12.10%;
			display: flex;
			align-items: center;

			.router-link-active {
				color: #E13036;
				font-weight: bold;
			}
		}

		.content {
			padding: 0 50px;

			.title {
				font-size: 30px;
				font-weight: bold;
			}

			.box-list {
				display: flex;
				margin-bottom: 50px; 

				.img {
					width: 15%;
				}

				.detail {
					width: 85%;
					font-size: 15px;

					.btn-practical {
						border: 1px #2C2C2C solid;
						border-radius: 0px;
					}
				}
			}

			.table-pra {
				thead {
					tr {
						&.theader {
							background: #E13036;
							color: #FFFFFF;
						}

						th {
							border: 1px #707070 solid;
							
						}
					}
				}

				tbody {
					tr {
						td {
							border: 1px #707070 solid;
						}
					}
				}
			}
		}

		@media only screen and(min-width: 992px) {
			.content {
				padding: 0 200px;
			}
		}
		
	}
</style>